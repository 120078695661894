$font-family-sans-serif: DINPro-Regular, san-serif;
$headings-font-family: DINPro-Bold, san-serif;
$line-height-base: 1.7;
$gray-900: #002945;
$blue: #5284c4;
$card-cap-bg: white;

@import "~bootstrap/scss/bootstrap.scss";
@import "~react-datepicker/src/stylesheets/datepicker";

html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #f6f7fb;
}

#root {
  display: flex;
}

.card {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.card-header-tabs {
  margin-left: 0;
  margin-bottom: 0;
}

.invalid-feedback {
  display: block;
}

.custom-control {
  z-index: auto;
}

.react-toast-notifications__container {
  z-index: 1041 !important;
}

.modal-open .modal {
  overflow-x: auto !important;
}

@font-face {
  font-family: "DINPro-Regular";
  src: url(/fonts/DINPro-Regular.woff2) format("woff2"),
  url(/fonts/DINPro-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "DINPro-Bold";
  src: url(/fonts/DINPro-Bold.woff2) format("woff2"),
  url(/fonts/DINPro-Bold.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DINPro-Light";
  src: url(/fonts/DINPro-Light.woff2) format("woff2"),
  url(/fonts/DINPro-Light.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DINPro-Black";
  src: url(/fonts/DINPro-Black.woff2) format("woff2"),
  url(/fonts/DINPro-Black.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
